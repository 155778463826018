<template>
  <v-toolbar app color="blue-lighten-1" style=" top: 0; width: 100%; position: fixed; z-index: 1;" :elevation="3">
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="authStore.selectedOrganismo"
      style="color: #01579B;"></v-app-bar-nav-icon>
    <v-toolbar-title @click="goToHome" style="cursor: pointer;" class="text-subtitle-1 text-lg-h4">Hub de <span
        style="color: #01579B; font-weight: bold;">certificados</span></v-toolbar-title>

    <v-toolbar-items v-if="authStore.selectedOrganismo">
      <!-- Seleccion de un organismo -->
      <v-btn>
        <SelectOrganismo v-if="screenWidth > 600" style="width: 300px" />
      </v-btn>
      <!-- Boton de notificaciones -->
      <v-btn min-width="60px" d-flex icon @click="toggleNotifications">
        <v-icon style="max-width: 30px;">
          mdi-bell
        </v-icon>
        <v-badge :content="notificationCount" color="red" overlap>
          <template v-slot:badge>
            <span>{{ notificationCount }}</span>
          </template>
        </v-badge>
      </v-btn>
      <!-- Avatar -->
      <Avatar />
    </v-toolbar-items>
  </v-toolbar>

  <!-- Drawer for notifications -->
  <NotificacionesDrawer :notificaciones="notificaciones" v-model="notificationesDrawer" />

  <v-navigation-drawer v-model="drawer" class="navigation-drawer-style" v-if="authStore.selectedOrganismo">
    <v-list nav>
      <v-list-item v-if="screenWidth < 600">
        <SelectOrganismo style="width: 100%" />
      </v-list-item>

      <v-list-group value="Certificados"
        v-if="permisos['certificado.obtener'] || permisos['certificadoAcl.index'] || permisos['certificado.validarpdf']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Certificados" prepend-icon="mdi-file-document-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['certificado.obtener']"><router-link
            to="/certificados">Consultar</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline"
          v-if="permisos['certificadoAcl.index']"><router-link to="/certificados-acls">Certificados
            ACLS</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-text-box-check-outline"
          v-if="permisos['certificado.validarpdf']"><router-link to="/validar-certificados">Validar Certificado
            PDF</router-link></v-list-item>
        <v-list-group class="items" value="CRUD"
          v-if="permisos['certificado.index'] || permisos['certificado.store'] || permisos['certificado.show'] || permisos['certificado.update'] || permisos['certificado.destroy']">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="CRUD"></v-list-item>
          </template>
          <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/listar-certificados"
              v-if="permisos['certificado.index']">Ver todos</router-link></v-list-item>
          <v-list-item class="items" prepend-icon="mdi-plus-box-outline"
            v-if="permisos['certificado.store']"><router-link to="/nuevo-certificado">Nuevo
              certificado</router-link></v-list-item>
        </v-list-group>

      </v-list-group>

      <v-list-group value="Novedades"
        v-if="permisos['novedad.index'] || permisos['novedad.store'] || permisos['novedad.show'] || permisos['novedad.update'] || permisos['novedad.destroy']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Novedades" prepend-icon="mdi-new-box"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/novedades"
            v-if="permisos['novedad.index']">Ver
            todas</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline" v-if="permisos['novedad.store']"><router-link
            to="/nueva-novedad">Nueva novedad</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Usuarios" v-if="permisos['user.index'] || permisos['user.store']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Usuarios" prepend-icon="mdi-account-group"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/usuarios" v-if="permisos['user.index']">Ver
            todos</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline" v-if="permisos['user.store']"><router-link
            to="/nuevo-usuario">Nuevo
            usuario</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Organismos"
        v-if="permisos['organismo.store'] || permisos['organismo.index'] || permisos['tipoOrganismo.store'] || permisos['tipoOrganismo.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Organismos" prepend-icon="mdi-store"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/organismos"
            v-if="permisos['organismo.index']">Ver
            todos</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline" v-if="permisos['organismo.store']"><router-link
            to="/nuevo-organismo">Nuevo
            organismo</router-link></v-list-item>

        <v-list-group class="items" value="Tipos de organismo"
          v-if="permisos['tipoOrganismo.store'] || permisos['tipoOrganismo.index']">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Tipos de organismo"></v-list-item>
          </template>
          <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['tipoOrganismo.index']"><router-link
              to="/tipos-organismo">Ver todos</router-link></v-list-item>
          <v-list-item class="items" prepend-icon="mdi-plus-box-outline"
            v-if="permisos['tipoOrganismo.store']"><router-link to="/nuevo-tipo-organismo">Nuevo tipo de
              organismo</router-link></v-list-item>
        </v-list-group>
      </v-list-group>

      <v-list-group value="Provincias" v-if="permisos['provincia.store'] || permisos['provincia.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Provincias" prepend-icon="mdi-map-marker"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['provincia.index']"><router-link
            to="/provincias">Ver
            todas</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline" v-if="permisos['provincia.store']"><router-link
            to="/nueva-provincia">Nueva
            provincia</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Departamentos" v-if="permisos['departamento.store'] || permisos['departamento.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Departamentos" prepend-icon="mdi-map-marker-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['departamento.index']"><router-link
            to="/departamentos">Ver todos</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline"
          v-if="permisos['departamento.store']"><router-link to="/nuevo-departamento">Nuevo
            departamento</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Roles" v-if="permisos['rol.store'] || permisos['rol.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Roles" prepend-icon="mdi-smart-card"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['rol.index']"><router-link to="/roles">Ver
            todos</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline" v-if="permisos['rol.store']"><router-link
            to="/nuevo-rol">Nuevo
            rol</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Acciones" v-if="permisos['accion.store'] || permisos['accion.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Acciones" prepend-icon="mdi-gesture-tap"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['accion.index']"><router-link
            to="/acciones">Ver
            todas</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline" v-if="permisos['accion.store']"><router-link
            to="/nueva-accion">Nueva
            acción</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Acciones/Roles" v-if="permisos['rolAccion.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Acciones/Roles" prepend-icon="mdi-account-details"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link
            to="/acciones-roles">Ver</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Tipos de errores"
        v-if="permisos['tipoErrorCertificados.index'] || permisos['tipoErrorCertificados.store']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Tipos de errores" prepend-icon="mdi-alert-circle-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['tipoErrorCertificados.index']"><router-link
            to="/tipo-error">Ver todos</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline"
          v-if="permisos['tipoErrorCertificados.store']"><router-link to="/nuevo-tipo-error">Nuevo
            tipo de error</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Aviso de errores" v-if="permisos['avisoErroresCertificados.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Aviso de errores" prepend-icon="mdi-alert-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/aviso-error">Ver</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Auditoria" v-if="permisos['auditorias.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Auditoria" prepend-icon="mdi-file-search-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/auditorias">Ver</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/requests"
            v-if="permisos['request.listarArchivosLog']">Ver requests</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Notificaciones" v-if="permisos['notificacion.store'] || permisos['notificacion.index']">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Envio de Notificaciones" prepend-icon="mdi-bell-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye" v-if="permisos['notificacion.index']"><router-link
            to="/notificaciones">Ver todas</router-link></v-list-item>
        <v-list-item class="items" prepend-icon="mdi-plus-box-outline"
          v-if="permisos['notificacion.store']"><router-link to="/nueva-notificacion">Nueva
            notificación</router-link></v-list-item>
      </v-list-group>

      <v-list-group value="Mis notificaciones">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Mis notificaciones" prepend-icon="mdi-bell-outline"></v-list-item>
        </template>
        <v-list-item class="items" prepend-icon="mdi-eye"><router-link to="/notificacionesUsuarios">Ver
            todas</router-link></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>


<script setup>

import { useAuthStore } from "@/store/auth";
import Avatar from "./Avatar.vue";
import SelectOrganismo from "./SelectOrganismo.vue";
import NotificacionesDrawer from "./NotificacionesDrawer.vue";
import { ref, onMounted, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import axios from 'axios';
import { useEventStore } from "@/store/eventStore";

const authStore = useAuthStore();
const token = authStore.token;
const permisos = authStore.permisos;
const drawer = ref(false);
const notificationesDrawer = ref(false); // Drawer state for notifications
const notificationCount = ref(0);
const notificaciones = ref([]); // Array to hold notifications
const screenWidth = ref(window.innerWidth);
const router = useRouter();
const eventStore = useEventStore();


// Visualizar notificaciones
const toggleNotifications = () => {
  notificationesDrawer.value = !notificationesDrawer.value;
};

const cargarNotificaciones = async () => {
  axios.get("/api/notificacionPersonaOrganismo/listarNotificacionesPersonasOrganismo", {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      notificaciones.value = res.data.data; // Adjust according to your API response
      notificationCount.value = res.data.data.filter(n => n.read_at === null).length; // Update notification count
      // Filter unread notifications
      localStorage.setItem("notificaciones", JSON.stringify(notificaciones.value));

    })
    .catch((err) => {
      localStorage.setItem('error', JSON.stringify(err));
      router.push('/error-500');
    });
};

const goToHome = () => {
  router.push('/');
};

const actualizarContador = () => {
  // Encuentra la notificación en la lista localStorage y actualiza su estado de lectura
  const notificacionesActualizadas = JSON.parse(localStorage.getItem('notificaciones'));
  // Disminuyo el contador
  notificationCount.value -= 1;
  // Actualiza las notificaciones locales para reactividad
  notificaciones.value = notificacionesActualizadas;
};

onMounted(() => {
  cargarNotificaciones();
  eventStore.on('notification-read', actualizarContador);
});

onUnmounted(() => {
  eventStore.off('notification-read', actualizarContador);
});


watch(() => authStore.permisos, (nuevosPermisos) => {
  permisos.value = nuevosPermisos;
});

</script>


<style scoped>
a {
  text-decoration: none;
  color: black;
}

.v-list-item {
  font-size: 0.8rem;
}

.items {
  margin-left: -30px;
}

.navigation-drawer-style {
  position: fixed !important;
  top: 64px !important;
  height: 93vh !important;
  overflow-y: auto !important;

}
</style>
