// src/store/eventStore.js
import { defineStore } from 'pinia';

export const useEventStore = defineStore('eventStore', {
  state: () => ({
    events: {}
  }),
  actions: {
    emit(event, data) {
      if (this.events[event]) {
        this.events[event].forEach(callback => callback(data));
      }
    },
    on(event, callback) {
      if (!this.events[event]) {
        this.events[event] = [];
      }
      if (!this.events[event].includes(callback)) {
        this.events[event].push(callback);
      }
    },
    off(event, callback) {
      if (this.events[event]) {
        this.events[event] = this.events[event].filter(cb => cb !== callback);
      }
    }
  }
});
