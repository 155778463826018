import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { createPinia } from 'pinia';
import createPersistedState from 'pinia-plugin-persistedstate';
import axios from 'axios';
import { markRaw } from 'vue';
import VueParticles from 'vue-particles'

window.axios = axios
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || 'http://localhost';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;

const pinia = createPinia();

pinia.use(({store}) => {
  store.router = markRaw(router)
});

pinia.use(createPersistedState)

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(pinia)
  .use(VueParticles)
  .mount('#app')

  let mostrarAlerta = false;

window.axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && !mostrarAlerta) {
      mostrarAlerta = true;
      localStorage.clear();
      const aceptar = window.confirm('Su sesión expiró. Por favor, inicie sesión nuevamente');
      if(aceptar){
        window.location.reload();
      }
      return Promise.reject(error);
    }

    return Promise.reject(error)
  }
);
