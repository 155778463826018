import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/RecuperarPassView.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/CambiarPassView.vue')
  },
  {
    path: '/misdatos',
    name: 'misdatos',
    component: () => import('../views/DatosPersonalesView.vue')
  },
  {
    path: '/nuevo-usuario',
    name: 'nuevo-usuario',
    component: () => import('../views/CRUD/Usuarios/AltaView.vue'),
    meta: { permissions: ['user.store'] }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../views/CRUD/Usuarios/ListaView.vue'),
    meta: { permissions: ['user.index'] }
  },
  {
    path: '/editar-usuario-:id',
    name: 'editar-usuario',
    component: () => import('../views/CRUD/Usuarios/AltaView.vue'),
    props: true,
    meta: { permissions: ['user.update'] }
  },
  {
    path: '/nuevo-organismo',
    name: 'nuevo-organismo',
    component: () => import('../views/CRUD/Organismos/AltaView.vue'),
    meta: { permissions: ['organismo.store'] }
  },
  {
    path: '/organismos',
    name: 'organismos',
    component: () => import('../views/CRUD/Organismos/ListaView.vue'),
    meta: { permissions: ['organismo.index'] }
  },
  {
    path: '/editar-organismo-:id',
    name: 'editar-organismo',
    component: () => import('../views/CRUD/Organismos/AltaView.vue'),
    props: true,
    meta: { permissions: ['organismo.update'] }
  },
  {
    path: '/nuevo-tipo-organismo',
    name: 'nuevo-tipo-organismo',
    component: () => import('../views/CRUD/Organismos/Tipos/AltaView.vue'),
    meta: { permissions: ['tipoOrganismo.store'] }
  },
  {
    path: '/tipos-organismo',
    name: 'tipos-organismo',
    component: () => import('../views/CRUD/Organismos/Tipos/ListaView.vue'),
    meta: { permissions: ['tipoOrganismo.index'] }
  },
  {
    path: '/editar-tipo-organismo-:id',
    name: 'editar-tipo-organismo',
    component: () => import('../views/CRUD/Organismos/Tipos/AltaView.vue'),
    props: true,
    meta: { permissions: ['tipoOrganismo.update'] }
  },
  {
    path: '/provincias',
    name: 'provincias',
    component: () => import('../views/CRUD/Provincias/ListaView.vue'),
    meta: { permissions: ['provincia.index'] }
  },
  {
    path: '/nueva-provincia',
    name: 'nueva-provincia',
    component: () => import('../views/CRUD/Provincias/AltaView.vue'),
    meta: { permissions: ['provincia.store'] }
  },
  {
    path: '/editar-provincia-:id',
    name: 'editar-provincia',
    component: () => import('../views/CRUD/Provincias/AltaView.vue'),
    props: true,
    meta: { permissions: ['provincia.update'] }
  },
  {
    path: '/departamentos',
    name: 'departamentos',
    component: () => import('../views/CRUD/Departamentos/ListaView.vue'),
    meta: { permissions: ['departamento.index'] }
  },
  {
    path: '/nuevo-departamento',
    name: 'nuevo-departamento',
    component: () => import('../views/CRUD/Departamentos/AltaView.vue'),
    meta: { permissions: ['departamento.store'] }
  },
  {
    path: '/editar-departamento-:id',
    name: 'editar-departamento',
    component: () => import('../views/CRUD/Departamentos/AltaView.vue'),
    props: true,
    meta: { permissions: ['departamento.update'] }
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../views/CRUD/Roles/ListaView.vue'),
    meta: { permissions: ['rol.index'] }
  },
  {
    path: '/nuevo-rol',
    name: 'nuevo-rol',
    component: () => import('../views/CRUD/Roles/AltaView.vue'),
    meta: { permissions: ['rol.store'] }
  },
  {
    path: '/editar-rol-:id',
    name: 'editar-rol',
    component: () => import('../views/CRUD/Roles/AltaView.vue'),
    props: true,
    meta: { permissions: ['rol.update'] }
  },
  {
    path: '/acciones',
    name: 'acciones',
    component: () => import('../views/CRUD/Acciones/ListaView.vue'),
    meta: { permissions: ['accion.index'] }
  },
  {
    path: '/nueva-accion',
    name: 'nueva-accion',
    component: () => import('../views/CRUD/Acciones/AltaView.vue'),
    meta: { permissions: ['accion.store'] }
  },
  {
    path: '/editar-accion-:id',
    name: 'editar-accion',
    component: () => import('../views/CRUD/Acciones/AltaView.vue'),
    props: true,
    meta: { permissions: ['accion.update'] }
  },
  {
    path: '/acciones-roles',
    name: 'acciones-roles',
    component: () => import('../views/CRUD/Acciones-roles.vue'),
    meta: { permissions: ['rolAccion.index'] }
  },
  {
    path: '/certificados',
    name: 'certificados',
    component: () => import('../views/CRUD/Certificados.vue'),
    meta: { permissions: ['certificado.obtener'] }
  },
  {
    path: '/certificados-acls',
    name: 'certificados-acls',
    component: () => import('../views/CRUD/CertificadosACLS.vue'),
    meta: { permissions: ['certificadoAcl.index'] }
  },
  {
    path: '/validar-certificados',
    name: 'validar-certificados',
    component: () => import('../views/CRUD/Validar.vue'),
    meta: { permissions: ['certificado.validarpdf'] }
  },
  {
    path: '/listar-certificados',
    name: 'listar-certificados',
    component: () => import('../views/CRUD/Certificados/ListaView.vue'),
    meta: { permissions: ['certificado.index'] }
  },{
    path: '/nuevo-certificado',
    name: 'nuevo-certificado',
    component: () => import('../views/CRUD/Certificados/AltaView.vue'),
    meta: { permissions: ['certificado.store'] }
  },
  {
    path: '/editar-certificado-:id',
    name: 'editar-certificado',
    component: () => import('../views/CRUD/Certificados/AltaView.vue'),
    props: true,
    meta: { permissions: ['certificado.update'] }
  },
  {
    path: '/novedades',
    name: 'novedades',
    component: () => import('../views/CRUD/Novedades/ListaView.vue'),
    meta: { permissions: ['novedad.index'] }
  },{
    path: '/nueva-novedad',
    name: 'nueva-novedad',
    component: () => import('../views/CRUD/Novedades/AltaView.vue'),
    meta: { permissions: ['novedad.store'] }
  },
  {
    path: '/editar-novedad-:id',
    name: 'editar-novedad',
    component: () => import('../views/CRUD/Novedades/AltaView.vue'),
    props: true,
    meta: { permissions: ['novedad.update'] }
  },
  {
    path: '/soporte',
    name: 'soporte',
    component: () => import('../views/SoporteView.vue')
  },
  {
    path: '/tipo-error',
    name: 'tipo-error',
    component: () => import('../views/CRUD/TipoError/ListaView.vue'),
    meta: { permissions: ['tipoErrorCertificados.index'] }
  },
  {
    path: '/nuevo-tipo-error',
    name: 'nuevo-tipo-error',
    component: () => import('../views/CRUD/TipoError/AltaView.vue'),
    meta: { permissions: ['tipoErrorCertificados.store'] }
  },
  {
    path: '/editar-tipo-error-:id',
    name: 'editar-tipo-error',
    component: () => import('../views/CRUD/TipoError/AltaView.vue'),
    props: true,
    meta: { permissions: ['tipoErrorCertificados.update'] }
  },
  {
    path: '/aviso-error',
    name: 'aviso-error',
    component: () => import('../views/AvisoErroresView.vue'),
    meta: { permissions: ['avisoErroresCertificados.index'] }
  },
  {
    path: '/aviso-:id',
    name: 'aviso',
    component: () => import('../views/DetalleAvisoView.vue'),
    meta: { permissions: ['avisoErroresCertificados.show'] }
  },
  {
    path: '/auditorias',
    name: 'auditoria',
    component: () => import('../views/Auditorias.vue'),
    meta: { permissions: ['auditorias.index'] }
  },
  {
    path: '/auditorias-:id',
    name: 'auditoria-show',
    component: () => import('../views/DetalleAuditoria.vue'),
    meta: { permissions: ['auditorias.show'] }
  },
  {
    path: '/error-500',
    name: 'error-500',
    component: () => import('../views/Error500.vue')
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('../views/Requests.vue')
  },
  {
    path: '/notificaciones',
    name: 'notificaciones',
    component: () => import('../views/CRUD/Notificaciones/ListaView.vue'),
    meta: { permissions: ['notificacion.index'] }
  },
  {
    path: '/nueva-notificacion',
    name: 'nueva-notificacion',
    component: () => import('../views/CRUD/Notificaciones/AltaView.vue'),
    meta: { permissions: ['notificacion.store'] }
  },
  {
    path: '/editar-notificacion-:id',
    name: 'editar-notificacion',
    component: () => import('../views/CRUD/Notificaciones/AltaView.vue'),
    props: true,
    meta: { permissions: ['notificacion.update'] }
  },
  {
    path: '/notificacionesUsuarios',
    name: 'notificacionesUsuarios',
    component: () => import('../views/CRUD/NotificacionesUsuarios/ListaView.vue'),
  },
  {
    path: '/notificacionesUsuarios/:id',
    name: 'notificacionesUsuariosDetalle',
    component: () => import('../views/CRUD/NotificacionesUsuarios/DetailsView.vue'),
  }, 
]

const router = createRouter({
  history: createWebHashHistory(),
  base: '/#',
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.user) {
    auth.returnUrl = to.path;
    return next({ path: '/login' });
  }

  if (auth.user) {
    if (to.path === '/login') {
      return next({ path: '/', replace: true });
    }

    const requiredPermissions = to.meta.permissions;

    if (requiredPermissions) {
      const userPermissions = auth.authPermisos;
      const hasPermission = requiredPermissions.every(permission =>
        userPermissions.hasOwnProperty(permission)
      );

      if (!hasPermission) {
        return next({ name: 'home' });
      }
    }
  }

  next();
});

export default router
