<template>
    <!-- Drawer for notifications -->
    <v-navigation-drawer location="right" temporary style="position: fixed; top: 64px; height: 93vh; overflow-y: auto;">
      <v-list class="notification-item">
        <v-list-item-group>
          <v-list-item style="padding: 2px;" v-for="notification in limitedNotifications" :key="notification.id">
            <v-card @click="viewNotificacion(notification)" :class="['notification-card', { 'unread-notification': !notification.read_at, 'read-notification': notification.read_at }]" class="p-2" outlined>
              <v-row>
                <!-- Título y tipo de notificación en la misma fila -->
                <v-col cols="9">
                  <v-card-title class="notification-title">{{ notification.data.titulo }}</v-card-title>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <span class="notification-type">{{ notification.tipo_notificacion }}</span>
                </v-col>
              </v-row>
              <v-card-text class="notification-text">
                {{ truncateText(notification.data.cuerpo_notificacion) }}
              </v-card-text>
              <v-row>
                <v-col cols="9" >
                  <v-card-subtitle class="notification-subtitle d-flex justify-start">
                    {{ notification.persona_organismo_notificador.persona_organismo.persona.apellido + ' ' +notification.persona_organismo_notificador.persona_organismo.persona.nombre}}
                  </v-card-subtitle>
                </v-col>
                <v-col cols="3">
                  <v-card-subtitle class="notification-subtitle d-flex justify-end">{{ fecha(notification.fecha_notificacion) }}</v-card-subtitle>
                </v-col>
              </v-row>
              
            </v-card>
            <v-tooltip open-delay="400" activator="parent" location="bottom" maxWidth="250px">{{ notification.data.cuerpo_notificacion }}</v-tooltip>
          </v-list-item>
        </v-list-item-group>
        <!-- Boton de mas notificaciones -->
        <v-list-item v-if="props.notificaciones && (props.notificaciones.length > 8)" @click="viewMoreNotifications" class="view-more">
          <v-card-text class="text-center">
            <router-link to="/notificacionesUsuarios">Ver más</router-link>
          </v-card-text>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </template>
  
  <script setup>

import { useAuthStore } from "@/store/auth";
import { ref, onMounted, watch, computed} from "vue";
import { useRouter } from "vue-router";


const router = useRouter();

const props = defineProps({
  notificaciones: {
    type: Array,
    required: true
  },
  notificationesDrawer: {
    type: Boolean,
    required: true
  }
});

const authStore = useAuthStore();
const permisos = authStore.permisos;

const limitedNotifications = computed(() => {
      return props.notificaciones?.slice(0, 8);
    });

const viewMoreNotifications = () => {
  router.push('/notificacionesUsuarios');
};

const viewNotificacion = (notification) => {
  router.push(`/notificacionesUsuarios/${notification.id}`);
}

const timeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const seconds = Math.floor((now - date) / 1000);
  const interval = Math.floor(seconds / 31536000);

  if (interval > 1) return `${interval} años`;
  const month = Math.floor(seconds / 2592000);
  if (month > 1) return `${month} meses`;
  const week = Math.floor(seconds / 604800);
  if (week > 1) return `${week} semanas`;
  const day = Math.floor(seconds / 86400);
  if (day > 1) return `${day} días`;
  const hour = Math.floor(seconds / 3600);
  if (hour > 1) return `${hour} horas`;
  const minute = Math.floor(seconds / 60);
  if (minute > 1) return `${minute} minutos`;
  return `${Math.floor(seconds)} segundos`;
};

const fecha = (dateString) => {
  return timeAgo(dateString);
};

const truncateText = (text, maxLength = 50) => {
  if (text && (text.length > maxLength)) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

onMounted(() => {
  permisos.value = authStore.permisos; 
  
});
  </script>
  
  <style scoped>
.navigation-drawer-style{
  position: fixed !important; 
  top: 64px !important; 
  height: 93vh !important; 
  overflow-y: auto !important;

}

/**
Notificaciones
*/

.notification-item{
  padding: 0px !important; 
}
.unread-notification {
  cursor: pointer;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f1f8fd !important; /* Light cyan background */
}

.unread-notification:hover {
  background-color: #f1f1f1 !important; /* Light cyan background */
}

.read-notification {
  cursor: pointer;
  background-color: #fff !important; /* White background */
}

.read-notification:hover {
  background-color: #f1f1f1 !important; /* White background */
}

.notification-title {
  color: #42A5F5;
  font-size: 15px;
  font-weight: bold;
}

.notification-type {
  font-style: italic;
  margin: 10px;
  font-size: 11px;
  color: #8b8b8b; /* Color for the type of notification */
}

.notification-subtitle {
  font-size: 11px;
  color: #555;
  font-style: italic;
  margin-top: 5px;
  font-style: italic;
}

.notification-text {
  font-size: 12px;
  padding: 0px 15px;
}

.view-more{
  color: #42A5F5 !important;
}

.view-more:hover{
  text-decoration-line:underline;
}
  </style>
  