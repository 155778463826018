<template>
  <v-app>
   <NavBar v-if="authUser.permisos" />
    <v-main style="margin-top: 64px;">
      <router-view />
      <Ayuda v-if="authUser.user" />
    </v-main>
  </v-app>
</template>

<script setup>
import NavBar from './components/NavBar.vue';
import Ayuda from './components/Ayuda.vue';
import { useAuthStore } from '@/store/auth'
import { ref } from 'vue';

const authUser = ref(useAuthStore());
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

* {
  font-family: 'Ubuntu', sans-serif !important;
  scrollbar-width: thin !important;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #ebebeb;
}

*::-webkit-scrollbar-thumb {
  background-color: #1565C0;
  border-radius: 20px;
}

.slide-top {
	animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @keyframes slide-top {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.required {
    font-size: 1.2rem;
    color: red;
}

</style>
