<template>
    <v-btn id="ayuda" icon="mdi-help" size="large" color="blue-lighten-1" @click.stop="ayuda = !ayuda"></v-btn>
    <v-card id="chat" v-if="ayuda">
        <v-card-title class="font-weight-black">Ayuda</v-card-title>
        <v-card-text>
            <TransitionGroup name="fade" mode="out-in">
                <template v-for="(opc, index) in chat" :key="index">
                    <p class="mb-2 font-weight-bold">{{ titulos[index] }}</p>
                    <template v-for="chip in opc">
                        <v-chip v-if="chip.siguiente && typeof (chip.siguiente.siguiente) != 'string'" class="mr-2 mb-2"
                            rounded :color="chip.color" style="cursor: pointer;">
                            <p @click="siguiente(chip.siguiente, chip.nombre)">{{ chip.nombre }}</p>
                        </v-chip>
                        <p v-else>Mirá cómo resolverlo en: <a :href="chip.nombre" target="_blank">{{ chip.nombre }}</a></p>
                    </template>
                    <v-chip class="mr-2 mb-2" rounded color="orange" style="cursor: pointer;" v-if="index > 0 && opc.length > 0">
                        <p @click="volverMenuAnterior">Volver al menú anterior</p>
                    </v-chip>
                    <v-divider class="mb-4 mt-1"></v-divider>
                </template>
            </TransitionGroup>
        </v-card-text>
        <v-card-text class="my-10"></v-card-text>
    </v-card>
</template>

<script setup>
import { ref } from 'vue';

const ayuda = ref(false);

const micuenta = ref([
    { nombre: 'Olvidé mi contraseña', siguiente: 'https://youtu.be/S6RJ0UekwJ0?si=pWIocVLpiqZSiX6U', color: "#FF5733" },
    { nombre: 'Quiero modificar un dato', siguiente: 'https://youtu.be/_IE3V0PGBvs?si=0yOa64W_eH4CCnrV', color: "#006785" },
    { nombre: '¿Cómo cerrar sesion?', siguiente: 'https://youtu.be/TCmI9pW8jW4?si=P2yRsswh4-VAZJ2L', color: "#006785" }
]);
const certificados = ref([
    { nombre: '¿Cómo obtener un acta?', siguiente: 'https://youtu.be/jR4BYDaeuBA?si=zF1ajhxJ380894TA', color: "#00C1FF" },
    { nombre: 'No existe el acta', siguiente: 'link al pdf', color: "#FF3371" }, 
    { nombre: 'Acta erronea', siguiente: 'link al pdf', color: "#FF7000" }]
);
const errores = ref(['']);
const temas = ref([
    { nombre: 'Mi cuenta', siguiente: micuenta, color: "#3336FF" },
    { nombre: 'Certificados', siguiente: certificados, color: "#0A8500" },
    { nombre: 'Errores', siguiente: errores, color: "#EC33FF" }
]);

const chat = ref([temas.value]);
const titulos = ref(['Selecciona el tema de interés:'])

const siguiente = (sig, titulo) => {
    if (typeof (sig) == 'string') {
        chat.value.push([{ nombre: sig }])
    } else {
        chat.value.push(sig)
    }

    titulos.value.push(titulo)
    const chatWindow = document.getElementById('chat')
    chatWindow.scrollTop = chatWindow.scrollHeight
}

const volverMenuAnterior = () => {
    chat.value.pop();
    const chatWindow = document.getElementById('chat')
    chatWindow.scrollTop = chatWindow.scrollHeight
}
</script>

<style scoped>
#ayuda {
    position: fixed;
    bottom: -2vh;
    right: 4vw;
    transition: 0.5s;
}

#ayuda:hover {
    bottom: 4vh;
}

#chat {
    height: 80vh;
    width: 90%;
    max-width: 400px;
    position: fixed;
    bottom: 9.5%;
    right: 4%;
    overflow-y: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translateX(-50px);
}
</style>
