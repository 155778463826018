<template>
  <v-alert v-if="guardadoExitoso" type="success" title="Guardado" text="Contraseña modificada exitosamente."
    closable></v-alert>
  <div style="display: flex; height: 100%; align-items: center; justify-content: center; flex-direction: column;">
    <div class="text-h3 text-center">
      <p>Bienvenido/a</p>
    </div>
    <div class="text-h2 text-center">
      <p>{{ authStore.user }}</p>
    </div>
    <br>
    <div class="text-h3 text-center">
      <p>Estás trabajando en el organismo:</p>
    </div>
    <div class="text-h2 text-center">
      <p>{{ authStore.nombreOrganismo }}</p>
    </div>
    <br>
    <div class="text-h4 text-center">
      <p>Puedes cambiar de organismo arriba a la derecha <v-icon icon="mdi-arrow-top-right-bold-outline"></v-icon></p>
    </div>
  </div>

</template>

<script setup>
import { useAuthStore } from '@/store/auth';
import { useRoute } from 'vue-router';
const authStore = useAuthStore();

const route = useRoute();
const guardadoExitoso = route.query.guardadoExitoso === 'true';
</script>