import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {

  state: () => ({ authUser: null, idPersona: null, authOrganismos: [], authPermisos: null, selectedOrganismo: null, authToken: null, error: null, nombreOrganismo: null }),
  getters: {
    user: (state) => state.authUser,
    organismos: (state) => state.authOrganismos,
    permisos: (state) => state.authPermisos,
    token: (state) => state.authToken,
  },
  actions: {
    async login(form) {
      this.authToken = null;
          this.authUser = null;
          this.idPersona = null;
          this.selectedOrganismo = null;
          this.authOrganismos = null;
          this.nombreOrganismo = null;
          this.authPermisos = null;
          this.error = null;
          localStorage.removeItem('auth');
      await axios
        .post("/api/login", form)
        .then((res) => {
          this.authOrganismos = [{ id: 0, nombre: '' }]
          this.authToken = res.data.data.access_token;
          this.authUser = res.data.data.user.email;
          this.idPersona = res.data.data.user.id;
          this.datosPersona(res.data.data.user.id, res.data.data.user.organismo_actual);
          this.cargarPermisos();
        })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
    async logout() {
      await axios
        .get("/api/logout", {
          headers: { Authorization: `Bearer ${this.authToken}` },
        })
        .finally(() => {
          this.authToken = null;
          this.authUser = null;
          this.idPersona = null;
          this.selectedOrganismo = null;
          this.authOrganismos = null;
          this.nombreOrganismo = null;
          this.authPermisos = null;
          this.error = null;
          localStorage.removeItem('auth');
          localStorage.removeItem('error');
          this.router.push("/login");
        });
    },
    async datosPersona(id, orgActual) {
      await axios
        .get(`api/personaOrganismo/${id}`, {
          headers: { Authorization: `Bearer ${this.authToken}` },
        })
        .then((res) => {
          this.authOrganismos = res.data.data.organismoPersona;
          this.selectedOrganismo = this.authOrganismos.find((org) => org.id == orgActual);
          this.nombreOrganismo = this.selectedOrganismo.organismo.nombre;
        })
        .catch((err) => {
          localStorage.setItem('error', JSON.stringify(err));
          router.push('/error-500');
        });
    },

    async setSelectedOrganismo(organismo) {
      this.selectedOrganismo = organismo;
      const org = {
        "organismo_id": this.selectedOrganismo.id,
      }
      await axios
        .put(`api/user/cambioOrganismo`, org, {
          headers: { Authorization: `Bearer ${this.authToken}` },
        })
        .then((res) => {
          this.cargarPermisos();
          this.nombreOrganismo = this.selectedOrganismo.organismo.nombre;
        })
        .catch((err) => {
          localStorage.setItem('error', JSON.stringify(err));
          router.push('/error-500')
        })
    },

    async cargarPermisos() {
      await axios.get(`/api/rolAccion/showAccionesUser`, {
        headers: { Authorization: `Bearer ${this.authToken}` },
      })
        .then((res) => {
          this.authPermisos = res.data.data
        })
        .catch((err) => {
          localStorage.setItem('error', JSON.stringify(err));
          router.push('/error-500');
        })
    }
  },
  persist: true,
});
