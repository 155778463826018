<template>
  <v-select :items="organismos" item-title="organismo.nombre" item-value="organismo" item-text="organismo.nombre"
    label="Organismos" v-model="selectedOrganismo" density="compact" class="mt-2" variant="underlined"
    @update:modelValue="selected" :disabled="isSelectDisabled"></v-select>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const authStore = useAuthStore();
const organismos = ref([]);
const selectedOrganismo = ref('');

const seleccionarOrganismo = () => {
  organismos.value = authStore.authOrganismos;
  if (authStore.selectedOrganismo != null) {
    selectedOrganismo.value = authStore.selectedOrganismo;
  }
};

const selected = () => {
  const org = authStore.organismos.find(
    (org) => org.organismo.id == selectedOrganismo.value.id
  );
  authStore.setSelectedOrganismo(org);
  authStore.router.push('/');
  /* setTimeout(() => {
    window.location.reload()
  }, 1000) */
};

const isSelectDisabled = computed(() => {
  const prefix = ["/editar", "/nuevo"];

  return prefix.some((route) =>
    router.currentRoute.value.path.startsWith(route)
  );
});

onMounted(async () => {
  seleccionarOrganismo();
});
</script>