<template>
    <v-container class="mt-1 mr-2">
        <v-row justify="end">
          <v-menu min-width="200px" rounded>
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props">
                <v-avatar color="blue-darken-3" size="large">
                  <span class="text-h5">{{
                    extraerIniciales(username)
                  }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <div class="mx-auto text-center">
                  <v-avatar color="blue-darken-3">
                    <span class="text-h5">{{
                      extraerIniciales(username)
                    }}</span>
                  </v-avatar>
                  <h3>{{ username }}</h3>
                  <v-divider class="mt-1"></v-divider>
                  <router-link to="/misdatos"><v-btn rounded variant="text" class="my-text"> Mis datos </v-btn></router-link>
                  <v-divider></v-divider>
                  <router-link to="/change-password"><v-btn rounded variant="text" class="my-text"> Cambiar contraseña </v-btn></router-link>
                  <v-divider></v-divider>
                  <router-link to="/soporte"><v-btn variant="text" class="my-text">Soporte</v-btn></router-link>
                  <v-divider></v-divider>
                  <v-btn rounded variant="text" @click.stop="authStore.logout" class="my-text-sesion"> Cerrar sesión </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-row>
      </v-container>
</template>

<script setup>
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();

const username = authStore.user;
    
const extraerIniciales = (str) =>  {
      const palabras = str.split(" "); // Dividir el string en palabras
      const iniciales = palabras.map((palabra) => palabra[0].toUpperCase()); // Obtener la inicial de cada palabra
      return iniciales.join(""); // Concatenar las iniciales
    }
  

</script>

<style scoped>
.my-text{
  color: #42A5F5;
}

.my-text:hover{
  color: #3d8be4;
}

.my-text-sesion{
  color: black;
}

.my-text-sesion:hover{
  color: rgb(109, 109, 109);
}
</style>
